import './App.css';
import {Blog, Vacancy} from './types/data';

import { HashRouter as Router, Routes, Route } from "react-router-dom"
import IndexPage from './pages/IndexPage/IndexPage';
import VacanciesPage from './pages/VacanciesPage/VacanciesPage';
import JobVacancyPage from './pages/JobVacancyPage/JobVacancyPage';
import PortfolioPage from './pages/PortfolioPage/PortfolioPage';
import ContactsPage from './pages/ContactsPage/ContactsPage';
import { useEffect, useState } from 'react';

import "./i18n"
import BlogsPage from "./pages/BlogsPage/BlogsPage";
import BlogArticlePage from "./pages/BlogArticlePage/BlogArticlePage";

function App() {
    const [vacancies, setVacancies] = useState<Vacancy[]>([]);

   //todo delete after implementing blog cards fetching from backend
    const blogPosts: Blog[] = [
        { id: 0, title: "BlockRock 2021: Global Overview city", tags: ["Events", "Tech"], shortDesc: "In this blog post, we will take a look at the global overview of BlockRock and the incredible progress that it has..." },
        { id: 1, title: "Future power", tags: ["Tech", "Tech"] , shortDesc: "The power of web development in the future will be immense. It has the potential to shape how we"},
        { id: 2, title: "Smart city", tags: ["Event", "Tech"] , shortDesc: "Urban living is quickly becoming a reality for many people around the world. With cities continuing to grow and" },
        { id: 3, title: "12 must-have features for your eCommerce mobile app", tags: ["Features"], shortDesc: "When it comes to developing a mobile app for your eCommerce business, there are certain features you must have to make your app an ..." },
        { id: 4, title: "MVC vs MVP vs MVVM – common Android architecture patterns explained", tags: ["Tech"] },
        { id: 0, title: "BlockRock 2021: Global Overview city", tags: ["Event", "Tech"], shortDesc: "In this blog post, we will take a look at the global overview of BlockRock and the incredible progress that it has..." },
        { id: 1, title: "Future power", tags: ["Tech", "Event"], shortDesc: "The power of web development in the future will be immense. It has the potential to shape how we" },
        { id: 2, title: "Smart city", tags: ["Event", "Tech"], shortDesc: "Urban living is quickly becoming a reality for many people around the world. With cities continuing to grow and" },
        { id: 0, title: "BlockRock 2021: Global Overview city", tags: ["Events", "Business strategy"], shortDesc: "In this blog post, we will take a look at the global overview of BlockRock and the incredible progress that it has..." },
        { id: 1, title: "Future power", tags: ["Tech", "Software"], shortDesc: "The power of web development in the future will be immense. It has the potential to shape how we" }
    ];

    useEffect(() => {


        fetch("/data/vacancies.json",
            {
                headers: {
                    "Content-Type": "application/json",
                    'Accept': "application/json"
                }
            }
        )
            .then(response => response.json())
            .then(json => {
                const vac: Vacancy[] = JSON.parse(JSON.stringify(json));

                setVacancies(vac);
            })


    }, [])

    const getLimitVacancy = (count: number, vacancies: Vacancy[]): Vacancy[] => {
        if (count >= vacancies.length) {
            return vacancies;
        }
        let vac: Vacancy[] = [];
        for (let i = 0; i < count; i++) {
            vac.push(vacancies[i]);
        }

        return vac;
    }

    return (
        <Router>
            <Routes>

                <Route path="/blogs" element={<BlogsPage blogPosts={blogPosts} />} />
                <Route path="/blogs/:id" element={<BlogArticlePage blogPosts={blogPosts} />} />
                <Route path="/portfolio" element={<PortfolioPage />} />
                <Route path="/vacancies" element={<VacanciesPage vacancies={vacancies} />} />
                <Route path="/vacancy/:id" element={<JobVacancyPage vacancies={vacancies} />} />
                <Route path="/contacts" element={<ContactsPage />} />
                <Route path="/" element={<IndexPage blogPosts={blogPosts} vacancies={getLimitVacancy(3, vacancies)} />} />

            </Routes>
        </Router>
    );
}

export default App;
