import ButtonGetStarted from "../ButtonGetStarted/ButtonGetStarted";
import "./InterestedBlock.css"

import {useTranslation} from "react-i18next"

const InterestedBlock:React.FC = ({children}) =>{
    const {t} = useTranslation();

    return(
        <div className={"interested-block"}>
            <h3>{t('interestedBlock.question')}</h3>
            <h2>{children}</h2>
            <ButtonGetStarted text={t('applyNow')} />
        </div>
    )
} 

export default InterestedBlock;