import { FC } from "react";
import { Blog } from "../../types/data";
import "./BlogCardItem.css";
import blogCardImg from "../../images/blog-temp/blog-card-1.jpg";
import { useNavigate } from "react-router";

interface BlogCardItemProps {
    card: Blog,
    cardType: "regular" | "home-page" | "img-full" | "no-img"
}

const BlogCardItem: FC<BlogCardItemProps> = ({card, cardType}) => {
    const navigate = useNavigate();
    const { id, title, tags, shortDesc } = card;

    return(
        <div className={`blog-card ${cardType}`}>
            <div className="blog-card-full-link" onClick={() => navigate(`/blogs/${id}`)}></div>
            <div className="blog-card-image">
                <img src={blogCardImg} alt="Card img"/>
                <div className="blog-card-tags">
                    { tags.map((tag, index) => <div key={index} className="blog-card-tag-item" onClick={() => console.log(tag)}>{tag}</div>) }
                </div>
            </div>
            <div className="blog-card-bottom">
                <h4 className="blog-card-title">{title}</h4>
                <p className="blog-card-text">{shortDesc}</p>
                <span className="blog-card-link" onClick={() => navigate(`/blogs/${id}`)}>
                    Read more
                    <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_581_6670)">
                            <path d="M16.8385 4.11007L13.0629 0.147544C12.8408 -0.0632294 12.4976 -0.0632294 12.2957 0.168621C12.0938 0.379394 12.0938 0.737708 12.2957 0.948481L15.1425 3.92038H0.565313C0.262462 3.8993 0.0201821 4.13115 -7.92718e-06 4.44731C-0.020198 4.76347 0.201892 5.0164 0.504743 5.03747C0.524933 5.03747 0.545123 5.03747 0.565313 5.03747H15.1627L12.2957 8.03045C12.0736 8.24122 12.0534 8.59954 12.2553 8.83139C12.4572 9.06324 12.8005 9.08431 13.0226 8.87354C13.0427 8.85246 13.0427 8.85246 13.0629 8.83139L16.8385 4.93209C17.0404 4.70024 17.0404 4.34192 16.8385 4.11007Z" fill="#8549F2"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_581_6670">
                                <rect width="17" height="9" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </span>
            </div>
        </div>
    )
}
export default BlogCardItem;