import Container from "../../components/Container/Container";
import Content from "../../components/Content/Content";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import "./PortfolioPage.css"

import portfolio1 from "../../images/portfolio1.png";
import portfolio2 from "../../images/portfolio2.png";
import portfolio3 from "../../images/portfolio3.png";
import portfolio4 from "../../images/portfolio4.png";
import portfolio5 from "../../images/portfolio5.png";
import portfolio6 from "../../images/portfolio6.png";
import InterestedBlock from "../../components/InterestedBlock/InterestedBlock";


import { useTranslation } from "react-i18next"


const PortfolioPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <main className={"main main-small"}>
                <Container>
                    <Header />
                    <Content>
                        <h1>{t('portfolioPage.title')}</h1>
                    </Content>
                </Container>
            </main>
            <Container>
                <div className={"text-portfolio"}>
                    <div className={"grid-text-portfolio"}>
                        <div>
                            <h3>{t('portfolioPage.text1.title')}</h3>
                            <h5>{t('portfolioPage.text1.subTitle')}</h5>
                            <a href="#asd">{t('portfolioPage.text1.link')}</a>
                        </div>
                        <div>
                            <p>
                                {t('portfolioPage.text1.description')}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="grid-images grid-columns-1">
                    <img src={portfolio1} alt="Portfolio 1" />
                </div>
                <div className={"text-portfolio"}>
                    <h4>{t('portfolioPage.subTitle1')}</h4>
                    <div className={"grid-text-portfolio"}>
                        <div>
                            <p className="bold">
                                {t('portfolioPage.text2.title')}
                            </p>
                        </div>
                        <div>
                            <p>
                                {t('portfolioPage.text2.description')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="grid-images grid-columns-2">
                    <img src={portfolio2} alt="Portfolio 2" />
                    <img src={portfolio3} alt="Portfolio 3" />
                </div>
                <div className={"text-portfolio"}>
                    <h4 style={{ color: "#3963EF" }}>{t('portfolioPage.subTitle2')}</h4>
                    <div className={"grid-text-portfolio"}>
                        <div>
                            <p className="bold">
                                {t('portfolioPage.text3.title')}
                            </p>
                        </div>
                        <div>
                            <p>
                                {t('portfolioPage.text3.description')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="grid-images grid-columns-3">
                    <img src={portfolio4} alt="Portfolio 4" />
                    <img src={portfolio5} alt="Portfoli0 5" />
                    <img src={portfolio6} alt="Portfolio 6" />
                </div>

                <h2 className={"portfolio-center-text"}>{t('portfolioPage.text4')}</h2>

                <InterestedBlock>{t('portfolioPage.interestedBlockText')}</InterestedBlock>

            </Container>
            <footer>
                <Container>
                <Footer title={t('footer.wantToJoinOurTeam')} description={t('footer.text')}/>
                </Container>
            </footer>
        </>
    )
}

export default PortfolioPage;