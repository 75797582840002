import "./VacanciesBenefitsItem.css";

interface VacanciesBenefitsItemProps {
    id: number,
    title: string,
    text: string,
    icon: string,
}

const VacanciesBenefitsItem = ({ item }: { item: VacanciesBenefitsItemProps }) => {
    const {title, text, icon} = item;
    return(
        <div className="benefits-item">
            <div className="benefits-item-image">
                <img src={icon} alt="Icon"/>
            </div>
            <h6>{title}</h6>
            <p>{text}</p>
        </div>
    )
}
export default VacanciesBenefitsItem;