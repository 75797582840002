import { MouseEventHandler, useEffect, useState } from "react";
import { DropdownElement } from "../../types/data";
import DropdownItem from "../DropdownItem/DropdownItem";
import "./Dropdown.css"

interface DropdownProps{
    title: string,
    values: DropdownElement[],
    onChange: (value:string) => void
}

const Dropdown: React.FC<DropdownProps> = ({title, values, onChange}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleOpen: MouseEventHandler<HTMLDivElement> = ():void =>{
        setTimeout(()=>setIsOpen(true), 100)
    }

    useEffect(() =>{

        /*const isDropdown = (elem: Element):boolean =>{
            if(elem.classList.contains("dropdown")){
                return true;
            }
            else{
                if(elem.parentElement !== null){
                    return isDropdown(elem.parentElement);
                }
            }
    
            return false;
        }*/
        
        const click = (e: MouseEvent):void =>{
        
            //const target = e.target as HTMLDivElement;

            setIsOpen(false);

        }

        document.addEventListener("click", click);

        return () =>{
            document.removeEventListener('click', click)
        }
    }, [])
    
    const selectItem = (value: string) =>{
        setIsOpen(false);
        onChange(value);
    }


    return (
        <div className={"dropdown " + (isOpen ? "openDropdown" : "")}>
            <div className="dropdown-text" onClick={toggleOpen}>
                <span>{title}</span>
                <span>
                    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.593777 0.252001C0.397777 0.448001 0.397777 0.764001 0.593777 0.960001L3.91778 4.284C4.07378 4.44 4.32578 4.44 4.48178 4.284L7.80578 0.960001C8.00178 0.764001 8.00178 0.448 7.80578 0.252C7.60978 0.0560004 7.29378 0.0560004 7.09778 0.252001L4.19778 3.148L1.29778 0.248002C1.10578 0.0560012 0.785777 0.0560007 0.593777 0.252001Z" fill="#303030" />
                    </svg>
                </span>
            </div>
            {isOpen ? <div className="dropdown-select">
                <ul>
                    {values.map(item => <DropdownItem onClick={(e) => selectItem(item.value)} key={item.value}>{item.text ?? item.value}</DropdownItem>)}
                </ul>
            </div> : null}
        </div>
    )
}

export default Dropdown;