import { FC } from "react";
import "./Title.css"

export enum TitleType {
    Centered,
    LeftWithDescription
}

interface TitleProps {
    type?: TitleType,
    description?: string | null,
    children: string
    className?: string
}

const Title: FC<TitleProps> = ({ type = TitleType.LeftWithDescription, description, className, children }) => {
    return (
        <div className={`title ${className ? className : ""}`}>
            {description ? <p>{description}</p> : null}
            <h2 style={type === TitleType.Centered ? { textAlign: "center" } : {}}>{children}</h2>
        </div>
    )
}

export default Title;