import "./Card.css"

interface CardProps {
  icon: JSX.Element,
  backgroundColorIcon: string
  title: string,
  description: string,
}


const Card: React.FC<CardProps> = ({ icon, backgroundColorIcon, title, description }) => {
  return (
    <div className="card">
      <div className="card-title">
        <div className="card-icon" style={{ background: backgroundColorIcon }}>
          {icon}
        </div>
        <h4>
          {title}
        </h4>
      </div>
      <div className="card-content">
        <p>{description}</p>
      </div>
    </div>
  )
}

export default Card;