import { FC, useEffect, useState } from "react";
import "./BlogArticlePage.css"
import { useParams, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Blog } from "../../types/data";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import Content from "../../components/Content/Content";
import Footer from "../../components/Footer/Footer";
import SubscribeNewsForm from "../../components/SubscribeNewsForm/SubscribeNewsForm";
import Title, {TitleType} from "../../components/Title/Title";
import BlogCardItem from "../../components/BlogCardItem/BlogCardItem";
import Button from "../../components/Button/Button";
import showdown from 'showdown';
interface BlogArticlePageProps {
    blogPosts: Blog[]
}

const BlogArticlePage: FC<BlogArticlePageProps> = ({blogPosts}) => {
    const [currentBlog, setCurrentBlog] = useState<Blog | null>(null);
    const [blogContent, setBlogContent] = useState<string | null>(null);

    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [params.id])

    useEffect(() => {
        const current = blogPosts.filter(blog => blog.id === Number(params.id));
        current.length > 0 ? setCurrentBlog(current[0]) : setCurrentBlog(null);
    }, [blogPosts, params.id]);
    useEffect(() => {

        fetch(`/data/blog/${params.id}.md`,
            {
                headers: {
                    "Content-Type": "application/text",
                    'Accept': "application/text"
                }
            }
        )
            .then(response => response.text())
            .then(text => {
                const converter = new showdown.Converter();
                const content = converter.makeHtml(text)
                setBlogContent(content)
            })


    }, [params.id])

    return(
        currentBlog &&
        <>
            <main className={"main main-small blog-article"}>
                <Container>
                    <Header />
                    <Content >
                        <h1>{currentBlog.title}</h1>
                        <p>Work on exciting, cutting-edge projects and be part of our skilled and dedicated team.</p>
                        <p className={'blog-article-breadcrumb'}>
                            <span onClick={() => navigate("/")}>Home</span>
                            <span className={"divider"}>/</span>
                            <span onClick={() => navigate("/blogs")}>Blogs</span>
                        </p>
                    </Content>
                </Container>
            </main>

            <Container>
                <div className="blog-article-wrapper">
                    <div className="blog-article-aside">
                        <h6 className="article-aside-title">What’s inside?</h6>
                        <ol className="article-aside-wrapper">
                            <li className="active">Chapter 1</li>
                            <li>Chapter 2</li>
                            <li>Chapter 3</li>
                            <li>Chapter 4</li>
                        </ol>
                    </div>
                    <div className="blog-article-content"  dangerouslySetInnerHTML={{__html: blogContent}}/>
                    <div className="blog-article-filters">
                        <div className="article-filters-wrapper">

                            <div className="article-filters-item">
                                <h6 className="article-aside-title">Categories</h6>
                                <ul className="filters-item-list">
                                    <li>All</li>
                                    <li>Artificial Intelligence</li>
                                    <li>Events</li>
                                    <li>Business Strategy</li>
                                    <li>Software Development</li>
                                </ul>
                            </div>

                            <div className="article-filters-item">
                                <h6 className="article-aside-title">Posts by Date</h6>
                                <ul className="filters-item-list">
                                    <li>Newest</li>
                                    <li>Oldest</li>
                                    <li>May 2022</li>
                                    <li>April 2022</li>
                                    <li>February 2022</li>
                                </ul>
                            </div>

                        </div>

                        <div className="blog-article-contact">
                            <SubscribeNewsForm />

                            <div className="blog-article-share">
                                <h6 className="article-share-title">Share</h6>
                                <ul className="social-icons">
                                    <li>
                                        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M19.6876 1.89497C18.9619 2.2152 18.1834 2.43261 17.3666 2.52956C18.201 2.03011 18.8415 1.23687 19.1412 0.293794C18.3597 0.757987 17.4959 1.09291 16.5763 1.27506C15.8389 0.490635 14.7901 0 13.6267 0C11.3968 0 9.58699 1.80977 9.58699 4.03966C9.58699 4.35696 9.62224 4.66544 9.69275 4.95924C6.33469 4.79177 3.35857 3.18178 1.36664 0.737422C1.01997 1.33382 0.820189 2.02718 0.820189 2.76754C0.820189 4.16893 1.53411 5.4058 2.61821 6.12853C1.95717 6.10797 1.33433 5.92582 0.787872 5.62321C0.787872 5.64084 0.787872 5.65553 0.787872 5.67315C0.787872 7.62982 2.18045 9.26331 4.02841 9.63349C3.69055 9.72457 3.33212 9.77451 2.96488 9.77451C2.70341 9.77451 2.45074 9.74807 2.20396 9.70107C2.7181 11.3052 4.21057 12.4745 5.97627 12.5068C4.5925 13.5909 2.85324 14.2372 0.958272 14.2372C0.632161 14.2372 0.311926 14.2167 -0.00537109 14.1814C1.78089 15.3272 3.90502 15.9971 6.1878 15.9971C13.6178 15.9971 17.681 9.84209 17.681 4.50386C17.681 4.32758 17.6781 4.15424 17.6693 3.9809C18.4596 3.41094 19.1441 2.69996 19.6847 1.88909L19.6876 1.89497Z" fill="white"/>
                                        </svg>
                                    </li>
                                    <li>
                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_455_14402)">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M9.37674 6.77208V6.73486C9.37142 6.7455 9.36079 6.75613 9.35547 6.77208H9.37674Z" fill="white"/>
                                                <path d="M3.80069 5.2041H0.212646V16.0001H3.80069V5.2041Z" fill="white"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M2.0093 3.73156H1.98804C0.781395 3.73156 0 2.90233 0 1.86578C0 0.807973 0.802658 0 2.03056 0C3.25847 0 4.01329 0.807973 4.03987 1.86578C4.03987 2.90233 3.25847 3.73156 2.0093 3.73156Z" fill="white"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M16.7441 15.9999H13.1561V10.2219C13.1561 8.77071 12.6351 7.78201 11.3381 7.78201C10.3441 7.78201 9.75408 8.44646 9.49893 9.09497C9.40325 9.32354 9.38199 9.64779 9.38199 9.97204V15.9999H5.79395C5.79395 15.9999 5.84179 6.21922 5.79395 5.20394H9.38199V6.73483C9.86039 6.00128 10.7109 4.9541 12.6192 4.9541C14.9793 4.9541 16.7494 6.49563 16.7494 9.81257V15.9999H16.7441Z" fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_455_14402">
                                                    <rect width="16.7442" height="16" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </li>
                                    <li>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.7823 15.0128C12.6591 15.0128 12.5375 14.976 12.4335 14.9056L8.49114 12.232L6.37594 13.7536C6.20954 13.8736 5.99674 13.9024 5.80313 13.8336C5.61113 13.7648 5.46553 13.6048 5.41273 13.408L4.35193 9.38396L0.558325 7.93276C0.319925 7.84156 0.161524 7.61276 0.159924 7.35676C0.158324 7.10076 0.313525 6.87036 0.550325 6.77596L14.976 1.03675C15.0416 1.00955 15.1104 0.993549 15.1808 0.988749C15.2064 0.987149 15.2304 0.987149 15.256 0.988749C15.4 0.996749 15.5424 1.05595 15.6544 1.16475C15.6656 1.17595 15.6768 1.18715 15.6864 1.19995C15.7776 1.30555 15.8288 1.43355 15.8384 1.56315C15.84 1.59515 15.84 1.62875 15.8384 1.66235C15.8368 1.68635 15.8336 1.71035 15.8288 1.73275L13.3919 14.5088C13.3535 14.7136 13.2127 14.8864 13.0207 14.9664C12.9439 14.9968 12.8639 15.0128 12.7823 15.0128ZM8.83834 10.9664L12.3503 13.3488L14.2448 3.40795L7.40154 9.99196L8.82074 10.9552C8.82714 10.9584 8.83194 10.9616 8.83834 10.9664ZM5.91513 10.4368L6.38394 12.2176L7.39674 11.488L6.07033 10.5888C6.00953 10.5472 5.95674 10.496 5.91513 10.4368ZM2.48793 7.34236L5.09273 8.33756C5.27993 8.40956 5.42073 8.56636 5.47193 8.75836L5.80153 10.0112C5.81593 9.86716 5.88153 9.73116 5.98873 9.62876L12.5087 3.35515L2.48793 7.34236Z" fill="white"/>
                                        </svg>
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="blog-article-related">
                    <Title type={TitleType.Centered}>{"Related Posts"}</Title>
                    <div className="home-blog-list">
                        {
                            blogPosts.slice(0, 3).map(post => <BlogCardItem key={post.id} card={post} cardType="home-page"/>)
                        }
                    </div>
                    <Button onClick={() => navigate("/blogs")}>All Posts</Button>
                </div>
            </Container>

            <footer>
                <Container>
                    <Footer title={t('footer.wantToJoinOurTeam')} description={t('footer.text')}/>
                </Container>
            </footer>
        </>
    )
}
export default BlogArticlePage;