import { MouseEventHandler } from "react";
import { useNavigate } from "react-router";
import "./VacanciesListItem.css"
import {Requirement} from "../../types/data";

interface VacanciesListItemProps {
    id: number,
    text: string,
    specialization: string,
    location: string,
    typeWork: string,
    rates?: string,
    isHot?: boolean,
    requiremets?: Requirement[]
}

const VacanciesListItem: React.FC<VacanciesListItemProps> = ({id, text, specialization, location, typeWork, requiremets, isHot, rates }) => {
    let navigate = useNavigate();
    const reqList = requiremets[0].subtitles[0].items.slice(0, 3);

    const onClickHandler:MouseEventHandler<HTMLDivElement> = () =>{
        navigate(`/vacancy/${id}`);
    }

    const LinkArrow = () => {
        return(
            <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_309_100)">
                    <path d="M17.3385 4.61001L13.563 0.647482C13.3409 0.43671 12.9977 0.43671 12.7958 0.66856C12.5939 0.879333 12.5939 1.23765 12.7958 1.44842L15.6426 4.42032H1.06537C0.762523 4.39924 0.520243 4.63109 0.500053 4.94725C0.479863 5.26341 0.701953 5.51634 1.0048 5.53741C1.02499 5.53741 1.04518 5.53741 1.06537 5.53741H15.6628L12.7958 8.53039C12.5737 8.74116 12.5535 9.09948 12.7554 9.33133C12.9573 9.56318 13.3005 9.58425 13.5226 9.37348C13.5428 9.3524 13.5428 9.3524 13.563 9.33133L17.3385 5.43203C17.5404 5.20018 17.5404 4.84186 17.3385 4.61001Z" fill="#8549F2"/>
                </g>
                <defs>
                    <clipPath id="clip0_309_100">
                        <rect width="17" height="9" fill="white" transform="translate(0.5 0.5)"/>
                    </clipPath>
                </defs>
            </svg>
        )
    }

    return (
        <li className="vac">
            <div className="vac-link-full" onClick={onClickHandler}/>
            <div className="vac-top">
                <div className="vac-specialization">{specialization}</div>
                {
                    isHot &&
                    <div className="vac-hot">Hot</div>
                }
            </div>
            <div className="vac-name">{text}</div>
            {
                rates &&
                <div className="vac-rate">{rates}</div>
            }
            <ul className="vac-info">
                {
                    reqList.map((item, index) => {
                        return(
                            <li key={index} className="vac-info-item">{item}</li>
                        )
                    })
                }
            </ul>
            <div className="vac-bottom">
                <div className="vac-country">{`${location} / ${typeWork}`}</div>
                <div className="vac-link" onClick={onClickHandler}>
                    Learn more
                    <LinkArrow/>
                </div>
            </div>
        </li>
    )
}

export default VacanciesListItem;