import "./Header.css"
import Logo from "../Logo/Logo";
import Menu from "../Menu/Menu"
import MenuMobile from "../MenuMobile/MenuMobile";

const Header: React.FC = () => {
    return (
        <header>
            <Logo />
            {window.outerWidth >= 1280 ? <Menu /> : <MenuMobile />}
        </header>
    )
}

export default Header;