import {  useLocation } from "react-router";
import "./Menu.css"

import { useTranslation } from "react-i18next"

import { Link } from "react-router-dom";

const Menu: React.FC = () => {
    const {t} = useTranslation();
    let location = useLocation();


    return (
        <div className="menu">
            <ul>
                <li><Link to="/" className={(location.pathname==='/' ? "active" : "")}>{t('menu.areasOfExpertise')}</Link></li>
                {/*<li><Link to="/">{t('menu.technology')}</Link></li>*/}
                <li className={location.pathname==='/portfolio' ? "active" : ""}><Link to="/portfolio">{t('menu.portfolio')}</Link></li>
                <li className={location.pathname==='/contacts' ? "active" : ""}><Link to="/contacts">{t('menu.contacts')}</Link></li>
                <li className={"menu-button menu-custom-hover " + (location.pathname==='/vacancies' ? "active" : "")}><Link to="/vacancies">{t('menu.vacancies')}</Link></li>
            </ul>
        </div>
    )
}

export default Menu;