import { MouseEventHandler, useState } from "react";
import { useLocation } from "react-router";
import Logo from "../Logo/Logo";
import "./MenuMobile.css"

import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";

const MenuMobile: React.FC = () => {
    const { t } = useTranslation();

    let location= useLocation();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const closeMenu: MouseEventHandler<HTMLDivElement> = () => {
        setIsOpen(false);
    }


    const openMenu: MouseEventHandler<HTMLDivElement> = () => {
        setIsOpen(true);
    }

    return (
        <>
            <div className={"menu-mobile " + (isOpen ? "" : "closeMenu")}>
                <div className="menu-mobile-header">
                    <Logo />
                    <div className="menu-close" onClick={closeMenu}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.22566 0.810956C1.83514 0.420436 1.20197 0.420436 0.811452 0.810956C0.420922 1.20148 0.420922 1.83465 0.811452 2.22517L6.5862 7.9999L0.811512 13.7746C0.420982 14.1651 0.420982 14.7983 0.811512 15.1888C1.20203 15.5793 1.8352 15.5793 2.22572 15.1888L8.0004 9.4141L13.7751 15.1888C14.1656 15.5793 14.7988 15.5793 15.1893 15.1888C15.5798 14.7983 15.5798 14.1651 15.1893 13.7746L9.4146 7.9999L15.1893 2.22517C15.5799 1.83465 15.5799 1.20148 15.1893 0.810956C14.7988 0.420436 14.1657 0.420436 13.7751 0.810956L8.0004 6.5857L2.22566 0.810956Z" fill="white" />
                        </svg>

                    </div>
                </div>
                <ul>
                    <li><Link to="/" className={(location.pathname==='/' ? "active" : "")}>{t('menu.areasOfExpertise')}</Link></li>
                    {/*<li><Link to="/">{t('menu.technology')}</Link></li>*/}
                    <li className={location.pathname === '/portfolio' ? "active" : ""}><Link to="/portfolio">{t('menu.portfolio')}</Link></li>
                    <li className={location.pathname === '/contacts' ? "active" : ""}><Link to="/contacts">{t('menu.contacts')}</Link></li>
                    <li className={"menu-button menu-custom-hover " + (location.pathname === '/vacancies' ? "active" : "")}><Link to="/vacancies">{t('menu.vacancies')}</Link></li>
                </ul>
            </div>
            <div className="menu-burger" onClick={openMenu}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_72_1260)">
                        <path d="M1.93748 9.37495H30.0624C30.58 9.37495 30.9999 8.95492 30.9999 8.43748C30.9999 7.91996 30.5799 7.5 30.0624 7.5H1.93748C1.41995 7.5 1 7.92003 1 8.43748C1 8.95492 1.42003 9.37495 1.93748 9.37495Z" fill="white" />
                        <path d="M30.0624 14.0625H1.93748C1.41995 14.0625 1 14.4825 1 15C1 15.5174 1.42003 15.9375 1.93748 15.9375H30.0624C30.58 15.9375 30.9999 15.5174 30.9999 15C30.9999 14.4825 30.58 14.0625 30.0624 14.0625Z" fill="white" />
                        <path d="M30.0625 20.625H11.3125C10.795 20.625 10.375 21.045 10.375 21.5625C10.375 22.08 10.795 22.5 11.3125 22.5H30.0625C30.58 22.5 30.9999 22.0799 30.9999 21.5625C31 21.045 30.58 20.625 30.0625 20.625Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_72_1260">
                            <rect width="30" height="30" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </>
    )
}

export default MenuMobile;