import "./RequirementDescItem.css"



const RequirementDescItem: React.FC = ({children}) =>{
    return(
        <li className="req-description-list-item">
            {children}
        </li>
    )
}

export default RequirementDescItem;