import "./Footer.css"

import {useTranslation} from "react-i18next"
import ButtonGetStarted from "../ButtonGetStarted/ButtonGetStarted";

/*const iconTwitter: JSX.Element = (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_72_28)">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.6871 1.89497C18.9615 2.2152 18.1829 2.43261 17.3662 2.52956C18.2005 2.03011 18.841 1.23687 19.1407 0.293794C18.3592 0.757987 17.4954 1.09291 16.5759 1.27506C15.8384 0.490635 14.7896 0 13.6262 0C11.3963 0 9.5865 1.80977 9.5865 4.03966C9.5865 4.35696 9.62176 4.66544 9.69227 4.95924C6.33421 4.79177 3.35808 3.18178 1.36616 0.737422C1.01948 1.33382 0.819701 2.02718 0.819701 2.76754C0.819701 4.16893 1.53362 5.4058 2.61772 6.12853C1.95668 6.10797 1.33384 5.92582 0.787383 5.62321C0.787383 5.64084 0.787383 5.65553 0.787383 5.67315C0.787383 7.62982 2.17996 9.26331 4.02793 9.63349C3.69006 9.72457 3.33164 9.77451 2.96439 9.77451C2.70292 9.77451 2.45025 9.74807 2.20347 9.70106C2.71761 11.3052 4.21008 12.4745 5.97578 12.5068C4.59201 13.5909 2.85275 14.2372 0.957784 14.2372C0.631673 14.2372 0.311438 14.2167 -0.00585938 14.1814C1.78041 15.3272 3.90453 15.9971 6.18731 15.9971C13.6173 15.9971 17.6805 9.84209 17.6805 4.50386C17.6805 4.32758 17.6776 4.15424 17.6688 3.9809C18.4591 3.41094 19.1436 2.69996 19.6842 1.88909L19.6871 1.89497Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_72_28">
                <rect width="19.688" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
const iconLinkedin: JSX.Element = (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_72_23)">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.37674 6.77211V6.73489C9.37142 6.74553 9.36079 6.75616 9.35547 6.77211H9.37674Z" fill="white" />
            <path d="M3.80093 5.20398H0.212891V16H3.80093V5.20398Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.0093 3.73156H1.98804C0.781395 3.73156 0 2.90233 0 1.86578C0 0.807973 0.802658 0 2.03056 0C3.25847 0 4.01329 0.807973 4.03987 1.86578C4.03987 2.90233 3.25847 3.73156 2.0093 3.73156Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.7441 16H13.1561V10.2219C13.1561 8.77077 12.6351 7.78207 11.3381 7.78207C10.3441 7.78207 9.75408 8.44652 9.49893 9.09503C9.40325 9.3236 9.38199 9.64785 9.38199 9.9721V16H5.79395C5.79395 16 5.84179 6.21928 5.79395 5.204H9.38199V6.73489C9.86039 6.00134 10.7109 4.95416 12.6192 4.95416C14.9793 4.95416 16.7494 6.49569 16.7494 9.81263V16H16.7441Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_72_23">
                <rect width="16.7442" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
const iconTelegram: JSX.Element = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.7826 15.0128C12.6594 15.0128 12.5378 14.976 12.4338 14.9056L8.49138 12.232L6.37618 13.7536C6.20978 13.8736 5.99698 13.9024 5.80338 13.8336C5.61138 13.7648 5.46578 13.6048 5.41298 13.408L4.35218 9.38402L0.558569 7.93282C0.320169 7.84162 0.161769 7.61282 0.160169 7.35682C0.158569 7.10082 0.313769 6.87042 0.550569 6.77602L14.9762 1.03681C15.0418 1.00961 15.1106 0.99361 15.181 0.98881C15.2066 0.98721 15.2306 0.98721 15.2562 0.98881C15.4002 0.99681 15.5426 1.05601 15.6546 1.16481C15.6658 1.17601 15.677 1.18721 15.6866 1.20001C15.7778 1.30561 15.829 1.43361 15.8386 1.56321C15.8402 1.59521 15.8402 1.62881 15.8386 1.66241C15.837 1.68641 15.8338 1.71041 15.829 1.73281L13.3922 14.5088C13.3538 14.7136 13.213 14.8864 13.021 14.9664C12.9442 14.9968 12.8642 15.0128 12.7826 15.0128ZM8.83858 10.9664L12.3506 13.3488L14.245 3.40801L7.40178 9.99202L8.82098 10.9552C8.82738 10.9584 8.83218 10.9616 8.83858 10.9664ZM5.91538 10.4368L6.38418 12.2176L7.39698 11.488L6.07058 10.5888C6.00978 10.5472 5.95698 10.496 5.91538 10.4368ZM2.48817 7.34242L5.09298 8.33762C5.28018 8.40962 5.42098 8.56642 5.47218 8.75842L5.80178 10.0112C5.81618 9.86722 5.88178 9.73122 5.98898 9.62882L12.509 3.35521L2.48817 7.34242Z" fill="white" />
    </svg>
)*/

interface FooterProps{
    title: string,
    description: string,
    withButton?: boolean
}

const Footer: React.FC<FooterProps> = ({title, description, withButton = true}) => {
    const {t} = useTranslation();

    return (
        <div className="footer">
            <div>
                <div style={{marginTop: "50px"}}>Block Rock South, LLC:</div>
                <div>5401 S. Kirkman Rd.</div>
                <div>Suite 310</div>
                <div>Orlando FL 32819</div>
                <a href="email:blockrocksouth@gmail.com">info@blockrocksouth.com</a>
                <div>phone number: 407-926-0353</div>
                <div className="social-networks">
                    {/*<ButtonSoc href={"#sdfsf"} icon={iconTwitter} />
                    <ButtonSoc href={"#sdfsf"} icon={iconLinkedin} />
    <ButtonSoc href={"#sdfsf"} icon={iconTelegram} />*/}
                </div>
                <p>Copyright 2022 © blockrocksouth.com</p>
            </div>
            <div>
                <h3>{title}</h3>
                <p>{description}</p>
                {withButton ? <ButtonGetStarted text={t('getStarted')} /> : null }
            </div>
        </div>
    )
}

export default Footer;