import React from "react";
import "./Button.css"

interface ButtonProps {
    children: string,
    onClick: React.MouseEventHandler<HTMLDivElement>
}

const Button: React.FC<ButtonProps> = ({ children, onClick }) => {
    return (
        <div className="button" onClick={onClick}>
            {children}
        </div>
    )
}

export default Button;