import "./Technology.css"

import angularImg from "../../images/Angular.png"
import nodejsImg from "../../images/NodeJs.png"
import dockerImg from "../../images/Docker.png"
import phpImg from "../../images/PHP.png"
import redisImg from "../../images/Redis.png"
import symfonyImg from "../../images/Symfony.png"

const Technology: React.FC = () => {
  return (
    <div id={"technologies"} className="technology">
      <img src={angularImg} alt="angular" />
      <img src={nodejsImg} alt="nodejs" />
      <img src={dockerImg} alt="docker" />
      <img src={phpImg} alt="php" />
      <img src={redisImg} alt="redis" />
      <img src={symfonyImg} alt="symfony" />
    </div>
  )
}

export default Technology;