import "./RequirementItem.css"

interface RequirementItemProps {
    title: string
}

const RequirementItem: React.FC<RequirementItemProps> = ({title, children}) =>{
    return(
        <div className={"req"}>
            <div className={'req-title'}>{title}</div>
            <div className={'req-description'}>
                <ul>
                    {children}
                </ul>
            </div>
        </div>
    )
}

export default RequirementItem