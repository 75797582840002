import "./BlogsPage.css";
import { useTranslation } from "react-i18next";
import { Blog } from "../../types/data";
import { FC, useEffect } from "react";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import Content from "../../components/Content/Content";
import Title, { TitleType } from "../../components/Title/Title";
import Footer from "../../components/Footer/Footer";
import BlogCardItem from "../../components/BlogCardItem/BlogCardItem";
import Dropdown from "../../components/Dropdown/Dropdown";
import Button from "../../components/Button/Button";

interface BlogsPageProps {
    blogPosts: Blog[]
}

const BlogsPage: FC<BlogsPageProps> = ({blogPosts}) => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const createChunks = (array, size) => {
        const copy = array.concat();
        const chunks = [];

        while (copy.length) {
            chunks.push(copy.splice(0, size));
        }
        return chunks;
    }

    //Temp values for Dropdowns
    const tagsFilterValues = [
        {value: "All"},
        {value: "Artificial Intelligence"},
        {value: "Events"},
        {value: "Business Strategy"},
        {value: "Software Development"},
        {value: "Tech"}
    ]
    const onTagFilterChange = () => {
        console.log("Tag filter changed")
    }
    const dateFilterValues = [
        {value: "Newest"},
        {value: "Oldest"},
        {value: "May 2022"},
        {value: "April 2022"},
        {value: "February 2022"}
    ];
    const onDateFilterChange = () => {
        console.log("Date filter changed")
    }
    const onShowMoreClick = () => {
        console.log("Show more clicked")
    }

    return(
        <>
            <main className={"main blogs-page-header"}>
                <Container>
                    <Header />
                    <Content>
                        <h1>Blogs</h1>
                        <p>Work on exciting, cutting-edge projects and be part of our skilled and dedicated team.</p>
                    </Content>
                </Container>
            </main>

            <div className="blogs-page">
                <Container>

                    <Title>{"Latest Posts"}</Title>
                    <div className="latest-posts">
                        {
                            blogPosts.slice(2, 5).map((post, index) => {
                                const cardType = index === 0 ? "img-full" : "no-img";
                                return(
                                    <BlogCardItem key={`${post.id}`} card={post} cardType={cardType}/>
                                )
                            })
                        }
                    </div>

                    <div className="posts-filter">
                        <Title type={TitleType.Centered}>{"All Posts"}</Title>
                        <div className="posts-filter-dropdowns">
                            <div className="posts-filter-name">Filter</div>
                            <Dropdown onChange={onTagFilterChange} title={"Choose one"} values={tagsFilterValues} />
                            <Dropdown onChange={onDateFilterChange} title={"Choose one"} values={dateFilterValues} />
                        </div>
                    </div>

                    <div className="all-posts">
                        {
                            createChunks(blogPosts, 5).map((chunk, index) =>(
                                <div key={index} className="all-posts-pattern">
                                    {
                                        chunk.map((post, i) => <BlogCardItem key={post.id} card={post} cardType={i === 0 ? "img-full" : "regular"}/>)
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <Button onClick={onShowMoreClick}>SHOW MORE</Button>
                </Container>
            </div>

            <footer>
                <Container>
                    <Footer title={t('footer.wantToJoinOurTeam')} description={t('footer.text')}/>
                </Container>
            </footer>
        </>
    )
}
export default BlogsPage;