import "./ExpertisesGridItem.css"

interface ExpertisesGridItemProps {
    icon?: JSX.Element,
    title: string,
    description?: string | null,
    style?: object,
    className?: string
}


const ExpertisesGridItem: React.FC<ExpertisesGridItemProps> = ({ icon, title, description, style, className=""}) => {
    return (
        <div style={style} className={className}>
            <div className="grid-icon">
                {icon}
            </div>
            <div className="grid-text">
                <h6>{title}</h6>
                <p>{description}</p>
            </div>
        </div>
    )
}

export default ExpertisesGridItem;