import "./JobVacancyForm.css";
// import usFlagIcon from "../../icons/flags/US.jpg";
// import gbFlagIcon from "../../icons/flags/GB.jpg";
// import auFlagIcon from "../../icons/flags/AU.jpg";
import formImage from "../../images/cv-form-image.png";
import { useState, useRef } from "react";

interface JobVacancyFormValidationErrors {
    file?: string
    firstName?: string
   // phone?: string
    secondName?: string
}

const JobVacancyForm = () => {
    const [firstName, setFirstName] = useState<string>("");
    const [secondName, setSecondName] = useState<string>("");
    // const [currentPhoneCountry, setCurrentPhoneCountry] = useState<string>("us");
    // const [phoneSelectActive, setPhoneSelectActive] = useState<boolean>(false);
    // const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [linkedProfile, setLinkedProfile] = useState<string>("");
    const [coverLetter, setCoverLetter] = useState<string>("");
    const [fileLoaded, setFileLoaded] = useState(null);
    const [validationErrors, setValidationErrors] = useState<JobVacancyFormValidationErrors>({})
    const fileInput = useRef<HTMLInputElement>();

    // const phoneCountries = [
    //     { id: 0, name: "us", code: "+1", flag: usFlagIcon },
    //     { id: 1, name: "au", code: "+64", flag: auFlagIcon },
    //     { id: 2, name: "gb", code: "+44", flag: gbFlagIcon }
    // ]
    //
    // const currentCountry = phoneCountries.filter(item => item.name === currentPhoneCountry);

    const LinkedInIcon = () => {
        return(
            <div className="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_481_1947)">
                        <path d="M20.1402 23.7852H4.01897C1.89035 23.7852 0.158691 22.0572 0.158691 19.9329V3.8523C0.158691 1.728 1.89035 0 4.01897 0H20.1402C22.2682 0 23.9999 1.728 23.9999 3.8523V19.9335C23.9999 22.0578 22.2682 23.7852 20.1402 23.7852ZM8.21473 19.6635H8.21954V9.4039H5.02843V19.6635H8.21473ZM6.62158 8.00269C7.64371 8.00269 8.46909 7.17731 8.46909 6.15519C8.46909 5.13787 7.63891 4.30769 6.62158 4.30769C5.59947 4.30769 4.77408 5.13787 4.77408 6.15519C4.77408 7.17251 5.59947 8.00269 6.62158 8.00269ZM20.1587 19.6635V14.0394C20.1587 11.2754 19.5587 9.14957 16.334 9.14957C14.784 9.14957 13.7428 9.99895 13.3157 10.8051H13.2726V9.4039H10.2158V19.6635H13.4021V14.5865C13.4021 13.2477 13.6564 11.952 15.3167 11.952C16.9483 11.952 16.9724 13.4828 16.9724 14.6729V19.6635H20.1587Z" fill="#303030"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_481_1947">
                            <rect width="24" height="24" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        )
    }

    const FileAttachIcon = () => {
        return(
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.6001 12.3996L12.4001 18.5996C10.7001 20.2996 8.0001 20.2996 6.4001 18.5996C4.7001 16.8996 4.7001 14.1996 6.4001 12.5996L14.4001 4.59961C15.4001 3.69961 16.9001 3.69961 17.9001 4.59961C18.9001 5.59961 18.9001 7.19961 17.9001 8.09961L11.0001 14.9996C10.7001 15.2996 10.2001 15.2996 9.9001 14.9996C9.6001 14.6996 9.6001 14.1996 9.9001 13.8996L15.0001 8.79961C15.4001 8.39961 15.4001 7.79961 15.0001 7.39961C14.6001 6.99961 14.0001 6.99961 13.6001 7.39961L8.5001 12.5996C7.4001 13.6996 7.4001 15.3996 8.5001 16.4996C9.6001 17.4996 11.3001 17.4996 12.4001 16.4996L19.3001 9.59961C21.1001 7.79961 21.1001 4.99961 19.3001 3.19961C17.5001 1.39961 14.7001 1.39961 12.9001 3.19961L4.9001 11.1996C3.7001 12.3996 3.1001 13.9996 3.1001 15.5996C3.1001 19.0996 5.9001 21.7996 9.4001 21.7996C11.1001 21.7996 12.6001 21.0996 13.8001 19.9996L20.0001 13.7996C20.4001 13.3996 20.4001 12.7996 20.0001 12.3996C19.6001 11.9996 19.0001 11.9996 18.6001 12.3996Z" fill="#303030"/>
            </svg>
        )
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const file = fileInput.current.files[0] || "";
        let data = new FormData();
        data.append("firstName", firstName);
        data.append("secondName", secondName);
        //data.append("phone", currentCountry[0].code + phoneNumber);
        data.append("linkedProfile", linkedProfile);
        data.append("coverLetter", coverLetter);
        data.append("file", file);


        fetch("/mail/mail.php", {
            method: "post",
            body: data
        })
            .then(res => res.json())
            .then(data => afterFormSubmit(data))
            .catch(error => console.log(error))
    }

    const onInputFocus = ({target}) => {
        target.parentElement.classList.add("focused");
        target.parentElement.classList.remove("error");
    }

    const afterFormSubmit = (data) => {
        if(data.success){
            document.location.reload();
        } else if(data.errors){
            setValidationErrors(data.errors);
        } else {
            console.log(data);
        }
    }

    return(
        <div className="job-vacancy-submit">
            <div className="job-vacancy-form">
                <div className="job-vacancy-form-title">Fill the form</div>
                <form className="form" onSubmit={onFormSubmit}>
                    <div className={`form-group ${validationErrors?.firstName ? "error" : ""}`}>
                        <input
                            type="text" placeholder="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            onFocus={(e) => onInputFocus(e)}
                            onBlur={(e) => e.target.parentElement.classList.remove("focused")}
                        />
                        <span className="error-message">{validationErrors?.firstName}</span>
                    </div>
                    <div className={`form-group ${validationErrors?.secondName ? "error" : ""}`}>
                        <input
                            type="text"
                            placeholder="Second Name"
                            value={secondName}
                            onChange={(e) => setSecondName(e.target.value)}
                            onFocus={(e) => onInputFocus(e)}
                            onBlur={(e) => e.target.parentElement.classList.remove("focused")}
                        />
                        <span className="error-message">{validationErrors?.secondName}</span>
                    </div>
                    {/*<div className="phone-input">*/}
                    {/*    <div className={`country-selector ${phoneSelectActive ? "active" : ""}`}*/}
                    {/*         onClick={() => setPhoneSelectActive(!phoneSelectActive)}*/}
                    {/*         onBlur={() => setPhoneSelectActive(false)}*/}
                    {/*         tabIndex={0}*/}
                    {/*    >*/}
                    {/*        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*            <path fillRule="evenodd" clipRule="evenodd" d="M8.2205 10.7765C7.9265 11.0705 7.9265 11.5445 8.2205 11.8385L13.2065 16.8245C13.4405 17.0585 13.8185 17.0585 14.0525 16.8245L19.0385 11.8385C19.3325 11.5445 19.3325 11.0705 19.0385 10.7765C18.7445 10.4825 18.2705 10.4825 17.9765 10.7765L13.6265 15.1205L9.2765 10.7705C8.9885 10.4825 8.5085 10.4825 8.2205 10.7765Z" fill="#303030"/>*/}
                    {/*        </svg>*/}
                    {/*        <div className="flag">*/}
                    {/*            <img src={currentCountry[0].flag} alt={currentCountry[0].name}/>*/}
                    {/*        </div>*/}
                    {/*        <span>{currentCountry[0].code}</span>*/}
                    {/*        <div className={`country-dropdown ${phoneSelectActive ? "active" : ""}`}>*/}
                    {/*            {*/}
                    {/*                phoneCountries.filter(item => item.name !== currentPhoneCountry)*/}
                    {/*                    .map(item => {*/}
                    {/*                        const {id, name, code, flag} = item;*/}
                    {/*                        return(*/}
                    {/*                            <div className="country-dropdown-item" key={id} onClick={() => setCurrentPhoneCountry(name)}>*/}
                    {/*                                <div className="flag">*/}
                    {/*                                    <img src={flag} alt={name}/>*/}
                    {/*                                </div>*/}
                    {/*                                <span>{code}</span>*/}
                    {/*                            </div>*/}
                    {/*                        )*/}
                    {/*                    })*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className={`form-group ${validationErrors?.phone ? "error" : ""}`}>*/}
                    {/*        <input*/}
                    {/*            type="text"*/}
                    {/*            placeholder="Phone Number"*/}
                    {/*            value={phoneNumber}*/}
                    {/*            onChange={(e) => setPhoneNumber(e.target.value)}*/}
                    {/*            onFocus={(e) => onInputFocus(e)}*/}
                    {/*            onBlur={(e) => e.target.parentElement.classList.remove("focused")}*/}
                    {/*        />*/}
                    {/*        <span className="error-message">{validationErrors?.phone}</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="form-group linked">
                        <LinkedInIcon/>
                        <input
                            type="text"
                            placeholder="Profile link (not required)"
                            value={linkedProfile}
                            onChange={(e) => setLinkedProfile(e.target.value)}
                            onFocus={(e) => onInputFocus(e)}
                            onBlur={(e) => e.target.parentElement.classList.remove("focused")}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Cover letter (not required)"
                            value={coverLetter}
                            onChange={(e) => setCoverLetter(e.target.value)}
                            onFocus={(e) => onInputFocus(e)}
                            onBlur={(e) => e.target.parentElement.classList.remove("focused")}
                        />
                    </div>
                    <div className={`file-input ${validationErrors?.file ? "error" : ""} ${fileLoaded ? "loaded" : ""}`}>
                        <label htmlFor="cvFile">
                            <FileAttachIcon/>
                            {fileLoaded ? (<span className="filename">{fileLoaded}</span>) : "Upload CV"}
                        </label>
                        <input ref={fileInput} onChange={() => setFileLoaded(fileInput.current.files[0].name)} id="cvFile" type="file" accept=".doc, .docx, .pdf"/>
                        <span>20MB upload limit </span>
                        { validationErrors?.file && <span className="error-message">{validationErrors?.file}</span> }
                    </div>
                    <button className="form-submit" type="submit">Apply</button>
                    <div className="form-description">
                        We will add your contact details provided in this form to our system for contacting you. For more info please consult our <span>privacy policy</span>
                    </div>
                </form>
            </div>
            <div className="job-vacancy-image">
                <img src={formImage} alt="Form"/>
            </div>
        </div>
    )
}

export default JobVacancyForm;