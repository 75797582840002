import { FC } from "react";
import "./IndexPage.css";
import { useNavigate } from "react-router";
import Button from "../../components/Button/Button";
import Cards from "../../components/Cards/Cards";
import Container from "../../components/Container/Container";
import Content from "../../components/Content/Content";
import ExpertisesGrid from "../../components/ExpertisesGrid/ExpertisesGrid";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Portfolio from "../../components/Portfolio/Portfolio";
import Technology from "../../components/Technology/Technology";
import Title, { TitleType } from "../../components/Title/Title";
import VacanciesList from "../../components/VacanciesList/VacanciesList";
import VacanciesListItem from "../../components/VacanciesListItem/VacanciesListItem";
import BlogCardItem from "../../components/BlogCardItem/BlogCardItem";
import { Blog, Vacancy } from "../../types/data";

import { useTranslation } from "react-i18next"

interface IndexPageProps {
    vacancies: Vacancy[]
    blogPosts: Blog[]
}

const IndexPage: FC<IndexPageProps> = ({ vacancies, blogPosts }) => {
    const { t } = useTranslation();

    let navigate = useNavigate();

    return (
        <>
            <main className={"main"}>
                <Container>
                    <Header />
                    <Content>
                        <h1>{t('indexPage.title')}</h1>
                        <p>{t('indexPage.description')}</p>

                        {/*<ButtonGetStarted text={t('getStarted')}/>*/}
                    </Content>
                </Container>
            </main>
            <div className="home-page">
                <Container>
                    <Title className="top-title" description={t('indexPage.whyUs.description')}>{t('indexPage.whyUs.title')}</Title>
                    <Cards />

                    <Title type={TitleType.Centered}>{t(`indexPage.areasOfExpertise`)}</Title>
                    <ExpertisesGrid />

                    <Title description={t('indexPage.technology.description')}>{t('indexPage.technology.title')}</Title>
                    <Technology />

                    <Title type={TitleType.Centered}>{t('indexPage.portfolio')}</Title>
                    <Portfolio />

                    <Title description={t('indexPage.vacancies.description')}>{t('indexPage.vacancies.title')}</Title>
                    <VacanciesList>

                        {vacancies.map(item => <VacanciesListItem {...item} key={item.id} />)}

                    </VacanciesList>

                    <Button onClick={() => navigate("/vacancies")}>{t('indexPage.viewMoreVacancies')}</Button>

                    <div className="home-blog-title">
                        <Title type={TitleType.Centered}>{"Blog"}</Title>
                    </div>
                    <div className="home-blog-list">
                        {
                            blogPosts.slice(0, 3).map(post => <BlogCardItem key={post.id} card={post} cardType="home-page"/>)
                        }
                    </div>
                    <Button onClick={() => navigate("/blogs")}>All Posts</Button>
                </Container>
            </div>

            <footer>
                <Container>
                    <Footer title={t('footer.wantToJoinOurTeam')} description={t('footer.text')}/>
                </Container>
            </footer>
        </>
    )
}

export default IndexPage;