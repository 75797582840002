import { useTranslation } from "react-i18next";
import Title from "../Title/Title";
import VacanciesBenefitsItem from "../VacanciesBenefitsItem/VacanciesBenefitsItem";
import "./VacanciesBenefits.css";
import medical from "../../images/medical.png";
import flexibleWork from "../../images/flexible-work.png";
import paidSick from "../../images/paid-sick.png";
import fiveDayWork from "../../images/5-day-work.png";
import holidays from "../../images/holidays.png";


const VacanciesBenefits = () => {
    const { t } = useTranslation();

    const benefitsArr = [
        { id: 0, title: t('vacanciesPage.benefits.items.item1.title'), text: t('vacanciesPage.benefits.items.item1.text'), icon: medical },
        { id: 1, title: t('vacanciesPage.benefits.items.item2.title'), text: t('vacanciesPage.benefits.items.item2.text'), icon: flexibleWork },
        { id: 2, title: t('vacanciesPage.benefits.items.item3.title'), text: t('vacanciesPage.benefits.items.item3.text'), icon: paidSick },
        { id: 3, title: t('vacanciesPage.benefits.items.item4.title'), text: t('vacanciesPage.benefits.items.item4.text'), icon: fiveDayWork },
        { id: 4, title: t('vacanciesPage.benefits.items.item5.title'), text: t('vacanciesPage.benefits.items.item5.text'), icon: holidays }
    ]

    return(
        <div className="vacancies-benefits">
            <Title description={t('vacanciesPage.benefits.subTitle')}>{t('vacanciesPage.benefits.title')}</Title>
            <div className="benefits-wrapper">
                {
                    benefitsArr.map(item => <VacanciesBenefitsItem key={item.id} item={item}/>)
                }
            </div>
        </div>
    )
}
export default VacanciesBenefits;