import Container from "../../components/Container/Container";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./ContactsPage.css"

import { useTranslation } from "react-i18next"

const ContactsPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <main className={"main"}>
                <Container>
                    <Header />
                </Container>
            </main>
            <div className="divi">
                <Container>
                    <h1 className="title-contact">Our contacts</h1>
                    <div className="contacts-div">
                        <div>
                            <div className="con-item">
                                <h5>Company name</h5>
                                <p>Block Rock South, LLC</p>
                            </div>
                            <div className="con-item">
                                <h5>Phone number</h5>
                                <p>407-926-0353</p>
                            </div>
                            <div className="con-item">
                                <h5>Email</h5>
                                <p>info@blockrocksouth.com
</p>
                            </div>
                        </div>
                        <div>
                            <h4>5401 S. Kirkman Rd.
Suite 310, Orlando, FL 32819
</h4>
                        </div>
                    </div>
                </Container>
            </div>
            <footer>
                <Container>
                    <Footer title={t('footer.wantToJoinOurTeam')} description={t('footer.text')}/>
                </Container>
            </footer>
        </>
    )
}

export default ContactsPage;