import "./SubscribeNewsForm.css";

const SubscribeNewsForm = () => {

    return(
        <div className="subscribe-form">
            <h6 className="subscribe-form-title">Subscribe newsletter</h6>
            <form action="">
                <input type="email" placeholder="E-mail" className="subscribe-form-input"/>
                <button type="submit" className="subscribe-form-button">Subscribe</button>
            </form>
        </div>
    )
}
export default SubscribeNewsForm