import { useEffect, useState } from "react";
import Container from "../../components/Container/Container";
import Content from "../../components/Content/Content";
import Dropdown from "../../components/Dropdown/Dropdown";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Title from "../../components/Title/Title";
import VacanciesList from "../../components/VacanciesList/VacanciesList";
import VacanciesListItem from "../../components/VacanciesListItem/VacanciesListItem";
import VacanciesBenefits from "../../components/VacanciesBenefits/VacanciesBenefits";
import { DropdownElement, Vacancy } from "../../types/data";

import { useTranslation } from "react-i18next"
import useMediaQuery from "../../hook/useMediaQuery";
import "./VacanciesPage.css";
import vacanciesImage from "../../images/vacancies-page-img.png";
import Button from "../../components/Button/Button";


interface VacanciesPageProps {
    vacancies: Vacancy[]
}

const uniqueValue = (array: DropdownElement[]) => {
    let result: DropdownElement[] = [{ text: "All", value: "" }];

    let uniques = new Set(array.map(arr => arr.value));

    uniques.forEach(value => result.push({ value }));

    return result;
}

const VacanciesPage: React.FC<VacanciesPageProps> = ({ vacancies }) => {
    const { t } = useTranslation();
    const mobMatches = useMediaQuery("(max-width: 589px)");
    const smMatches = useMediaQuery("(min-width: 590px) and (max-width: 959px)");
    const [vacanciesToShow, setVacanciesToShow] = useState<number>(0);
    const [vacanciesShowStep, setVacanciesShowStep] = useState<number>(0);
    const [specialization, setSpecializatipn] = useState<string>("");
    const [filteredVacancies, setFilteredVacancies] = useState<Vacancy[] | null>(null);
    const [specializationValues, setSpecializationValues] = useState<DropdownElement[]>([]);
    
    const filteredVac= (vacs = vacancies) => {
        let change: boolean  = false;

        let v = vacs;

        if (specialization !== "") {
            v = v.filter(vac => vac.specialization === specialization);
            change = true;
        }

        if(change === false) return null;
        return v;
    }

    const onChangeSpecialization = (value: string) => {
        setSpecializatipn(value);
    }


    useEffect(() => {
        setFilteredVacancies(filteredVac());
        console.log("set filtered")
        // eslint-disable-next-line
    }, [specialization])

    useEffect(() => {
        let allLocation: DropdownElement[] = [];

        for (let i = 0; i < vacancies.length; i++) {
            let vac: DropdownElement = { value: vacancies[i].location };
            allLocation.push(vac);
        }

        let allSpecialization: DropdownElement[] = [];

        for (let i = 0; i < vacancies.length; i++) {
            let vac: DropdownElement = { value: vacancies[i].specialization };

            allSpecialization.push(vac);
        }
        allSpecialization = uniqueValue(allSpecialization);

        setSpecializationValues(allSpecialization);

        console.log(vacancies)
    }, [vacancies])

    useEffect(() => {
        if(mobMatches){
            setVacanciesShowStep(4);
            setVacanciesToShow(4);
        } else if(smMatches){
            setVacanciesShowStep(8);
            setVacanciesToShow(8);
        } else{
            setVacanciesShowStep(12);
            setVacanciesToShow(12);
        }
    }, [mobMatches, smMatches]);

    useEffect(()=>{
        window.scrollTo(0,0);
    }, [])

    return (
        <>
            <main className={"main main-small"}>
                <Container>
                    <Header />
                    <Content >
                        <h1>{t('vacanciesPage.title')}</h1>
                        <p className="content-vacancies">{t('vacanciesPage.titleText')}</p>
                    </Content>
                </Container>
            </main>
            <div className="vacancies-page">
                <Container>
                    <div className="vacancies-page-top">
                        <div className="vacancies-top-text">
                            <Title description={t('vacanciesPage.description')}>{t('vacanciesPage.subTitle')}</Title>
                            <p className="description-text">{t('vacanciesPage.descriptionText')}</p>
                        </div>
                        <div className="vacancies-top-img">
                            <img src={vacanciesImage} alt="Vacancies"/>
                        </div>
                    </div>

                    <div className="vacancies-filter">
                        <h2>Current openings</h2>
                        <Dropdown onChange={onChangeSpecialization} title={specialization === "" ? t('vacanciesPage.dropdownTitle2') : specialization} values={specializationValues} />
                    </div>

                    <VacanciesList>

                        {filteredVacancies ? filteredVacancies.slice(0, vacanciesToShow).map(item => { console.log("filtered")
                            return <VacanciesListItem {...item} key={item.id} />
                        }) : vacancies.slice(0, vacanciesToShow).map(item => {
                            console.log("vac vac")
                            return <VacanciesListItem {...item} key={item.id} /> })}

                    </VacanciesList>

                    {
                        !filteredVacancies?.length && vacancies?.length > vacanciesToShow ?
                        (
                            <Button onClick={() => setVacanciesToShow(vacanciesToShow + vacanciesShowStep)}>See more</Button>
                        ) : null
                    }
                    {
                        filteredVacancies?.length && filteredVacancies?.length > vacanciesToShow ?
                        (
                            <Button onClick={() => setVacanciesToShow(vacanciesToShow + vacanciesShowStep)}>See more</Button>
                        ) : null
                    }
                    <VacanciesBenefits/>

                </Container>
            </div>
            <footer>
                <Container>
                    <Footer title={t('footerVacancies.title')} description={t('footerVacancies.description')} withButton={false}/>
                </Container>
            </footer>
        </>
    )
}

export default VacanciesPage;