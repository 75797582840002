import { useNavigate } from "react-router";
import "./Logo.css"

const Logo: React.FC = () => {
    let navigate = useNavigate();

    return (
        <div className='logo' onClick={() => navigate("/")}><span>blockrock</span>south.com</div>
    )
}

export default Logo;