import { useEffect, useState, useRef } from "react";
import {useNavigate, useParams} from "react-router";
import Container from "../../components/Container/Container";
import Content from "../../components/Content/Content";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import RequirementDescItem from "../../components/RequirementDescItem/RequirementDescItem";
import RequirementItem from "../../components/RequirementItem/RequirementItem";
import RequirementSubItem from "../../components/RequirementSubItem/RequirementSubItem";

import "./JobVacancyPage.css"
import { Vacancy } from "../../types/data";

import {useTranslation} from "react-i18next"
import JobVacancyInfoCard from "../../components/JobVacancyInfoCard/JobVacancyInfoCard";
import JobVacancyForm from "../../components/JobVacancyForm/JobVacancyForm";
import useIntersectionObserver from "../../hook/useIntersectionObserver";


interface JobVacancyPageProps {
    vacancies: Vacancy[]
}

const JobVacancyPage: React.FC<JobVacancyPageProps> = ({ vacancies }) => {
    const {t} = useTranslation();
    let navigate = useNavigate();
    const [vacancy, setVacancy] = useState<Vacancy | null>(null);
    let params = useParams();
    const form = useRef<HTMLDivElement | null>(null);
    const requirements = useRef<HTMLDivElement | null>(null);
    const formEntry = useIntersectionObserver(form, {});
    const requirementsEntry = useIntersectionObserver(requirements, {});
    const formInViewport = !!formEntry?.isIntersecting;
    const requirementsInViewport = !!requirementsEntry?.isIntersecting;

    useEffect(() => {
        if (vacancies !== null || vacancies.length !== 0) {

            console.log(vacancies.length !== 0);

            let vac = null;
            if (params.id) {
                vac = vacancies.find(v => v.id === (params.id ? parseInt(params.id) : 0));
            }


            if (vac) {
                setVacancy(vac);
            }
            else {
                console.log("The vacancy with so id is not found");
            }
        }

    }, [params, vacancies])

    useEffect(()=>{
        window.scrollTo(0,0);
    }, [])

    const scrollToForm = () => form.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    return (
        <>
            <main className={"main main-small job-vacancy"}>
                <Container>
                    <Header />
                    <Content >
                        <h2>{vacancy?.text}</h2>
                        <p className={'description-job-title'}>
                            <span onClick={() => navigate("/")}>Home</span>
                            {/*{vacancy?.specialization}*/}
                            <span className={"divider"}>/</span>
                            <span onClick={() => navigate("/vacancies")}>Vacancies</span>
                            {/*{vacancy?.location}
                            <span className={"divider"}>/</span>*/}
                            {/*{vacancy?.typeWork}*/}
                        </p>
                        {/*<ButtonGetStarted text={t('applyNow')} />*/}
                    </Content>
                </Container>
            </main>
            <Container>
                {/*<p className={"subtitle-jobvacancy"}>
                    {vacancy?.subtitle}
                </p>
                <p className={"description-jobvacancy"}>
                    {vacancy?.description}
                </p>*/}
                <div className="job-vacancy-wrapper">

                    <JobVacancyInfoCard vacancy={vacancy} formInViewport={formInViewport} requirementsInViewport={requirementsInViewport} scroll={scrollToForm}/>

                    <div className={'requirements'} ref={requirements}>

                        <div className="job-vacancy-about-us">
                            <div className="about-title">{t("jobVacancyPage.aboutUs.title")}</div>
                            <div className="about-text">{t("jobVacancyPage.aboutUs.text")}</div>
                        </div>

                        {vacancy?.requiremets?.map(item => {
                            return (
                                <RequirementItem title={item.title} key={item.id}>
                                    {item.subtitles.map(subtitle => {
                                        return (
                                            <RequirementSubItem title={subtitle.title} key={subtitle.id}>
                                                {subtitle.items.map((l, index) => {
                                                    return (
                                                        <RequirementDescItem key={index}>{l}</RequirementDescItem>
                                                    )
                                                })}
                                            </RequirementSubItem>
                                        )
                                    })}
                                </RequirementItem>
                            )
                        })}

                    </div>
                </div>
                <div ref={form}>
                    <JobVacancyForm/>
                </div>
            </Container>
            <footer>
                <Container>
                <Footer title={t('footer.wantToJoinOurTeam')} description={t('footer.text')}/>
                </Container>
            </footer>
        </>
    )
}

export default JobVacancyPage;