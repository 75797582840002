import "./RequirementSubItem.css"

interface RequirementSubItemProps{
    title?: string
}

const RequirementSubItem: React.FC<RequirementSubItemProps> = ({title, children}) =>{
    return(
        <div className={"req-subitem"}>
            {title ? <h4>{title}</h4> : null}
            {children}
        </div>
    )
}

export default RequirementSubItem;