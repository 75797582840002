import "./VacanciesList.css"


const VacanciesList: React.FC = ({ children }) => {
    return (
        <ul className="vacancies">
            {children}
        </ul>
    )
}

export default VacanciesList;