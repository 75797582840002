import "./Portfolio.css"

import portfolioImage from "../../images/portfolioImage2.png"
import portfolioImage1 from "../../images/portfolioImage1.png"
import { useState } from "react"

import {useTranslation} from "react-i18next"

const maxPage = 5;

const Portfolio: React.FC = () => {
  const {t} = useTranslation();

  const [page, setPage] = useState<number>(1);

  const nextPage: React.MouseEventHandler<HTMLDivElement> = (): void => {
    console.log("dsa")
    if (page >= maxPage) {
      setPage(1);
    }
    else {
      setPage(page+1);
    }
  }

  return (
    <div className="portfolio">
      <div className="image">
        <img src={portfolioImage} alt={"portfolio"} />
      </div>
      <div className='portfolio-content'>
        <h5>{t(`portfolio.page${page}.title`)}</h5>

        <h6>{t(`portfolio.page${page}.subTitle1`)}</h6>
        <p>{t(`portfolio.page${page}.description1`)}</p>

        <h6 style={{ "color": "#3963EF" }}>{t(`portfolio.page${page}.subTitle2`)}</h6>
        <p>{t(`portfolio.page${page}.description2`)}</p>
        <a href="#sdas" style={{ "color": "#00ACD2" }}>{t(`portfolio.page${page}.link`)}</a>
      </div>
      <div className="ims" style={{ backgroundImage: `url(${portfolioImage1})` }}>
        <p>{t(`portfolio.page${page}.titleAnotherItem`)}</p>
      </div>
      <div className="button-next" onClick={nextPage}>
        <div>
          <span>{page}</span>
          <span>/</span>
          <span>{maxPage}</span>
        </div>
        <div>
          <span>{t('portfolio.next')}</span>
          <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8388 4.11001L13.0632 0.147482C12.8412 -0.0632904 12.4979 -0.0632904 12.296 0.16856C12.0941 0.379333 12.0941 0.737647 12.296 0.94842L15.1428 3.92032H0.565618C0.262768 3.89924 0.0204873 4.13109 0.000297249 4.44725C-0.0198928 4.76341 0.202197 5.01634 0.505048 5.03741C0.525238 5.03741 0.545428 5.03741 0.565618 5.03741H15.163L12.296 8.03039C12.0739 8.24116 12.0537 8.59948 12.2556 8.83133C12.4575 9.06318 12.8008 9.08425 13.0229 8.87348C13.0431 8.8524 13.0431 8.8524 13.0632 8.83133L16.8388 4.93203C17.0407 4.70018 17.0407 4.34186 16.8388 4.11001Z" fill="white" />
          </svg>
        </div>

      </div>
    </div>
  )
}

export default Portfolio;