import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LaguageDetector from "i18next-browser-languagedetector"

i18n
    .use(LaguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: "en",
        lng: "en",
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: {
                translation: require("./translations/en/translations.json")
            }
        }
    });

i18n.languages = ["en"];

export default i18n;