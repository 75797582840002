import { MouseEventHandler } from "react";
import "./DropdownItem.css"

interface DropdownItemProps{
    onClick: MouseEventHandler<HTMLLIElement>
}

const DropdownItem: React.FC<DropdownItemProps> = ({children, onClick}) =>{
    return(        
        <li className={"dropdown-item"} onClick={(onClick)}>{children}</li>
    )
}

export default DropdownItem;