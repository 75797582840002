import Card from "../Card/Card";
import "./Cards.css"

import {useTranslation} from "react-i18next"

const icon1: JSX.Element = (
    <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_72_207)">
            <path d="M6.36719 32.7266C6.8677 32.7266 7.27344 32.3208 7.27344 31.8203C7.27344 31.3198 6.8677 30.9141 6.36719 30.9141C5.86668 30.9141 5.46094 31.3198 5.46094 31.8203C5.46094 32.3208 5.86668 32.7266 6.36719 32.7266Z" fill="#5A19CF" />
            <path d="M10 32.7266C10.5005 32.7266 10.9062 32.3208 10.9062 31.8203C10.9062 31.3198 10.5005 30.9141 10 30.9141C9.49949 30.9141 9.09375 31.3198 9.09375 31.8203C9.09375 32.3208 9.49949 32.7266 10 32.7266Z" fill="#5A19CF" />
            <path d="M13.6328 32.7266C14.1333 32.7266 14.5391 32.3208 14.5391 31.8203C14.5391 31.3198 14.1333 30.9141 13.6328 30.9141C13.1323 30.9141 12.7266 31.3198 12.7266 31.8203C12.7266 32.3208 13.1323 32.7266 13.6328 32.7266Z" fill="#5A19CF" />
            <path d="M26.3672 7.27344C26.8677 7.27344 27.2734 6.8677 27.2734 6.36719C27.2734 5.86668 26.8677 5.46094 26.3672 5.46094C25.8667 5.46094 25.4609 5.86668 25.4609 6.36719C25.4609 6.8677 25.8667 7.27344 26.3672 7.27344Z" fill="#5A19CF" />
            <path d="M30 7.27344C30.5005 7.27344 30.9062 6.8677 30.9062 6.36719C30.9062 5.86668 30.5005 5.46094 30 5.46094C29.4995 5.46094 29.0938 5.86668 29.0938 6.36719C29.0938 6.8677 29.4995 7.27344 30 7.27344Z" fill="#5A19CF" />
            <path d="M33.6328 7.27344C34.1333 7.27344 34.5391 6.8677 34.5391 6.36719C34.5391 5.86668 34.1333 5.46094 33.6328 5.46094C33.1323 5.46094 32.7266 5.86668 32.7266 6.36719C32.7266 6.8677 33.1323 7.27344 33.6328 7.27344Z" fill="#5A19CF" />
            <path d="M8.17969 0H6.36719C4.82031 0 3.63281 1.17969 3.63281 2.72656V5.45313C3.63281 7.45313 5.26563 9.08594 7.26563 9.08594C9.26563 9.08594 10.8984 7.45313 10.8984 5.45313V2.72656C10.9063 1.17969 9.72657 0 8.17969 0ZM9.09375 5.45313C9.09375 6.45313 8.27344 7.27344 7.27344 7.27344C6.27344 7.27344 5.45313 6.45313 5.45313 5.45313V2.72656C5.45313 2.17969 5.82031 1.82031 6.35938 1.82031H8.17969C8.72657 1.82031 9.08594 2.1875 9.08594 2.72656L9.09375 5.45313Z" fill="#5A19CF" />
            <path d="M13.6328 18.1797H1.82031V15.4531C1.82031 13.9063 3 12.7266 4.54688 12.7266H10C11.5469 12.7266 12.7266 13.9063 12.7266 15.4531C12.7266 16 13.0937 16.3594 13.6328 16.3594C14.1719 16.3594 14.5391 15.9922 14.5391 15.4531C14.5391 12.9063 12.5391 10.9062 9.99219 10.9062H4.54688C2 10.9063 0 12.9063 0 15.4531V19.0859C0 19.6328 0.367188 19.9922 0.90625 19.9922H13.6328C14.1797 19.9922 14.5391 19.625 14.5391 19.0859C14.5391 18.5469 14.1797 18.1797 13.6328 18.1797Z" fill="#5A19CF" />
            <path d="M33.6328 18.1797H31.8125C30.2656 18.1797 29.0859 19.3594 29.0859 20.9063V23.6328C29.0859 25.6328 30.7187 27.2656 32.7188 27.2656C34.7188 27.2656 36.3516 25.6328 36.3516 23.6328V20.9063C36.3672 19.3672 35.1797 18.1797 33.6328 18.1797ZM34.5469 23.6328C34.5469 24.6328 33.7266 25.4531 32.7266 25.4531C31.7266 25.4531 30.9063 24.6328 30.9063 23.6328V20.9063C30.9063 20.3594 31.2734 20 31.8125 20H33.6328C34.1797 20 34.5391 20.3672 34.5391 20.9063L34.5469 23.6328Z" fill="#5A19CF" />
            <path d="M39.0938 36.3672H27.2734V33.6406C27.2734 32.0938 28.4531 30.9141 30 30.9141H35.4531C37 30.9141 38.1797 32.0938 38.1797 33.6406C38.1797 34.1875 38.5469 34.5469 39.0859 34.5469C39.625 34.5469 39.9922 34.1797 39.9922 33.6406C39.9922 31.0938 37.9922 29.0938 35.4453 29.0938H30C27.4531 29.0938 25.4531 31.0938 25.4531 33.6406V37.2734C25.4531 37.8203 25.8203 38.1797 26.3594 38.1797H39.0859C39.6328 38.1797 39.9922 37.8125 39.9922 37.2734C39.9922 36.7344 39.6328 36.3672 39.0938 36.3672Z" fill="#5A19CF" />
            <path d="M17.2734 25.4531H10.6406C9.91406 25.4531 9.1875 25.1797 8.73438 24.6328L6.1875 22.0859C5.82031 21.7188 5.28125 21.7188 4.91406 22.0859C4.54688 22.4531 4.54688 22.9922 4.91406 23.3594L7.46094 25.9062C8.28125 26.7266 9.46094 27.2734 10.6406 27.2734H17.2734C17.8203 27.2734 18.1797 27.6406 18.1797 28.1797V35.4531C18.1797 36 17.8125 36.3594 17.2734 36.3594H2.72656C2.17969 36.3594 1.82031 35.9922 1.82031 35.4531V28.1797C1.82031 27.6328 2.1875 27.2734 2.72656 27.2734H4.54688C5.09375 27.2734 5.45313 26.9062 5.45313 26.3672C5.45313 25.8203 5.08594 25.4609 4.54688 25.4609H2.72656C1.17969 25.4531 0 26.6328 0 28.1797V35.4531C0 37 1.17969 38.1797 2.72656 38.1797H17.2734C18.8203 38.1797 20 37 20 35.4531V28.1797C20 26.6328 18.8203 25.4531 17.2734 25.4531Z" fill="#5A19CF" />
            <path d="M37.2734 0H22.7266C21.1797 0 20 1.17969 20 2.72656V10C20 11.5469 21.1797 12.7266 22.7266 12.7266H29.3594C30.0859 12.7266 30.8125 13 31.2656 13.5469L33.8125 16.0937C33.9922 16.2734 34.2656 16.3672 34.4453 16.3672C34.625 16.3672 34.8984 16.2734 35.0781 16.0937C35.4453 15.7266 35.4453 15.1875 35.0781 14.8203L32.5312 12.2734C31.7109 11.4531 30.5313 10.9062 29.3516 10.9062H22.7187C22.1719 10.9062 21.8125 10.5391 21.8125 10V2.72656C21.8125 2.17969 22.1797 1.82031 22.7187 1.82031H37.2656C37.8125 1.82031 38.1719 2.1875 38.1719 2.72656V10C38.1719 10.5469 37.8047 10.9062 37.2656 10.9062H35.4453C34.8984 10.9062 34.5391 11.2734 34.5391 11.8125C34.5391 12.3594 34.9063 12.7187 35.4453 12.7187H37.2656C38.8125 12.7187 39.9922 11.5391 39.9922 9.99219V2.72656C40 1.17969 38.8203 0 37.2734 0Z" fill="#5A19CF" />
        </g>
        <defs>
            <clipPath id="clip0_72_207">
                <rect width="40" height="38.1797" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
const icon2: JSX.Element = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_72_250)">
            <path d="M6.57792 5.14251C6.18424 4.74882 5.5527 4.74882 5.15902 5.1343C4.75713 5.52799 4.75713 6.15952 5.15082 6.55321L7.54573 8.96453C7.73437 9.15317 7.98862 9.25979 8.25928 9.25979C8.52174 9.25979 8.77599 9.15317 8.96463 8.96453L16.256 1.71417C16.6497 1.32048 16.6497 0.688948 16.2642 0.295263C15.8705 -0.0984212 15.239 -0.0984212 14.8453 0.295263L12.639 2.48513C11.4662 1.27127 9.83402 0.574123 8.11985 0.574123C4.65051 0.574123 1.8291 3.39553 1.8291 6.86488C1.8291 10.3342 4.65051 13.1556 8.11985 13.1556C10.9167 13.1556 13.3362 11.3594 14.1481 8.68567C14.3122 8.15255 14.0087 7.59483 13.4756 7.4308C12.9425 7.27496 12.3848 7.57023 12.2207 8.10334C11.6794 9.89133 9.98985 11.1462 8.11985 11.1462C5.75775 11.1462 3.83853 9.22698 3.83853 6.86488C3.83853 4.50277 5.75775 2.58356 8.11985 2.58356C9.2927 2.58356 10.4081 3.07566 11.2119 3.91224L8.26748 6.84027L6.57792 5.14251Z" fill="#11B9EE" />
            <path d="M8.11986 13.9922C4.65051 13.9922 1.8291 16.8136 1.8291 20.2829C1.8291 23.7523 4.65051 26.5737 8.11986 26.5737C11.5892 26.5737 14.4106 23.7523 14.4106 20.2829C14.4106 16.8136 11.5892 13.9922 8.11986 13.9922ZM8.11986 24.5643C5.75775 24.5643 3.83854 22.645 3.83854 20.2829C3.83854 17.9208 5.75775 16.0016 8.11986 16.0016C10.482 16.0016 12.4012 17.9208 12.4012 20.2829C12.4012 22.645 10.482 24.5643 8.11986 24.5643Z" fill="#11B9EE" />
            <path d="M14.4106 33.7092C14.4106 30.2399 11.5892 27.4185 8.11986 27.4185C4.65051 27.4185 1.8291 30.2399 1.8291 33.7092C1.8291 37.1786 4.65051 40 8.11986 40C11.5892 40 14.4106 37.1786 14.4106 33.7092ZM8.11986 37.9987C5.75775 37.9987 3.83854 36.0795 3.83854 33.7174C3.83854 31.3553 5.75775 29.4361 8.11986 29.4361C10.482 29.4361 12.4012 31.3553 12.4012 33.7174C12.4012 36.0795 10.482 37.9987 8.11986 37.9987Z" fill="#11B9EE" />
            <path d="M37.162 19.2742H17.6172C17.0595 19.2742 16.6084 19.7253 16.6084 20.283C16.6084 20.8407 17.0595 21.2918 17.6172 21.2918H37.162C37.7197 21.2918 38.1708 20.8407 38.1708 20.283C38.1708 19.7253 37.7197 19.2742 37.162 19.2742Z" fill="#11B9EE" />
            <path d="M37.162 5.86426H17.6172C17.0595 5.86426 16.6084 6.31535 16.6084 6.87307C16.6084 7.43079 17.0595 7.88189 17.6172 7.88189H37.162C37.7197 7.88189 38.1708 7.43079 38.1708 6.87307C38.1708 6.31535 37.7197 5.86426 37.162 5.86426Z" fill="#11B9EE" />
            <path d="M37.162 32.7087H17.6172C17.0595 32.7087 16.6084 33.1598 16.6084 33.7176C16.6084 34.2753 17.0595 34.7264 17.6172 34.7264H37.162C37.7197 34.7264 38.1708 34.2753 38.1708 33.7176C38.1708 33.1516 37.7197 32.7087 37.162 32.7087Z" fill="#11B9EE" />
        </g>
        <defs>
            <clipPath id="clip0_72_250">
                <rect width="40" height="40" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
const icon3: JSX.Element = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_72_265)">
            <path d="M30.1416 4.15897C27.3667 1.42302 23.693 -0.0542474 19.7914 0.00152333C16.0374 0.0544396 12.4941 1.55421 9.81424 4.2244C7.13428 6.89469 5.622 10.4325 5.55602 14.1863C5.48718 18.1027 6.9617 21.7949 9.70819 24.5826C12.4059 27.3207 13.8915 30.8871 13.8915 34.6246C13.8915 36.9762 15.8048 38.8895 18.1564 38.8895H18.2344C18.2295 38.9297 18.2261 38.9703 18.2261 39.0119C18.2261 39.5577 18.6684 40 19.2141 40H20.7848C21.3306 40 21.7729 39.5577 21.7729 39.0119C21.7729 38.9704 21.7695 38.9297 21.7645 38.8895H21.8426C24.1942 38.8895 26.1074 36.9763 26.1074 34.6245C26.1074 30.8881 27.5947 27.2656 30.1878 24.6861C32.9333 21.955 34.4453 18.3182 34.4453 14.4459C34.4452 10.5488 32.9168 6.89546 30.1416 4.15897ZM21.8426 36.9134H18.1564C17.1719 36.9134 16.3332 36.2875 16.0107 35.4136H23.9881C23.6657 36.2874 22.827 36.9134 21.8426 36.9134ZM24.1753 33.4374H15.8238C15.7898 32.98 15.7354 32.5262 15.6628 32.0763H24.3356C24.2636 32.5266 24.2092 32.9806 24.1753 33.4374ZM28.7941 23.285C26.9033 25.1659 25.5283 27.5295 24.7797 30.1001H15.214C14.4596 27.5427 13.0675 25.1766 11.1157 23.1957C8.74505 20.7894 7.4722 17.6021 7.5317 14.221C7.64874 7.56383 13.1609 2.07141 19.8192 1.97743C19.8798 1.97655 19.94 1.97611 20.0005 1.97611C23.2994 1.97611 26.402 3.24698 28.754 5.56608C31.1497 7.92821 32.4691 11.0818 32.4691 14.4458C32.4691 17.7883 31.164 20.9275 28.7941 23.285Z" fill="#FF640D" />
            <path d="M27.4027 13.0311H26.9173C26.7261 11.8656 26.2451 10.7735 25.5077 9.84123L25.8183 9.53065C26.2042 9.14476 26.2042 8.5192 25.8183 8.13331C25.4324 7.74742 24.8068 7.74742 24.4209 8.13331L24.0778 8.4765C23.1184 7.7876 22.006 7.35549 20.8254 7.21771V6.77835C20.8254 6.23261 20.3831 5.79028 19.8373 5.79028C19.2916 5.79028 18.8493 6.23261 18.8493 6.77835V7.26371C17.6841 7.45495 16.5918 7.93592 15.6593 8.67334L15.3487 8.36276C14.9628 7.97687 14.3372 7.97687 13.9513 8.36276C13.5654 8.74865 13.5654 9.37421 13.9513 9.7601L14.2945 10.1033C13.6056 11.0627 13.1735 12.175 13.0357 13.3557H12.5965C12.0507 13.3557 11.6084 13.798 11.6084 14.3437C11.6084 14.8895 12.0507 15.3318 12.5965 15.3318H13.0818C13.2731 16.4974 13.754 17.5894 14.4915 18.5217L14.1809 18.8323C13.795 19.2182 13.795 19.8437 14.1809 20.2296C14.3738 20.4225 14.6267 20.519 14.8795 20.519C15.1324 20.519 15.3852 20.4225 15.5782 20.2296L15.9214 19.8864C16.8808 20.5753 17.9932 21.0074 19.1738 21.1452V21.5846C19.1738 22.1303 19.6161 22.5726 20.1618 22.5726C20.7076 22.5726 21.1499 22.1303 21.1499 21.5846V21.0994C22.3154 20.9082 23.4074 20.427 24.3397 19.6896L24.6504 20.0003C24.8433 20.1932 25.0962 20.2897 25.3491 20.2897C25.6019 20.2897 25.8547 20.1932 26.0477 20.0003C26.4336 19.6144 26.4336 18.9888 26.0477 18.6029L25.7046 18.2598C26.3935 17.3003 26.8256 16.188 26.9633 15.0074H27.4026C27.9483 15.0074 28.3907 14.5651 28.3907 14.0193C28.3908 13.4735 27.9484 13.0311 27.4027 13.0311ZM23.5277 17.7763C22.5675 18.7187 21.2925 19.231 19.9524 19.218C18.6071 19.2053 17.3472 18.6695 16.4049 17.7093C15.4625 16.749 14.9505 15.4793 14.9632 14.134C14.9759 12.7887 15.5117 11.5288 16.4719 10.5866C17.4205 9.65559 18.6711 9.14465 19.9985 9.14465C20.0146 9.14465 20.031 9.14465 20.0472 9.14487C21.3925 9.15749 22.6524 9.69335 23.5947 10.6535C24.5371 11.6138 25.0491 12.8835 25.0364 14.2288C25.0237 15.5741 24.4879 16.834 23.5277 17.7763Z" fill="#FF640D" />
            <path d="M20.0331 10.5682C19.067 10.5562 18.1571 10.9264 17.4682 11.6024C16.7793 12.2785 16.3949 13.1824 16.3859 14.1476C16.3768 15.1127 16.7441 16.0236 17.4202 16.7125C18.0962 17.4014 19.0001 17.7857 19.9653 17.7947C19.977 17.7949 19.9885 17.7949 20.0001 17.7949C20.9523 17.7949 21.8497 17.4283 22.5302 16.7605C23.2191 16.0844 23.6035 15.1805 23.6125 14.2153C23.6215 13.2503 23.2543 12.3393 22.5782 11.6504C21.9022 10.9615 20.9983 10.5772 20.0331 10.5682ZM21.146 15.3501C20.8338 15.6565 20.4227 15.8244 19.9838 15.8187C19.5465 15.8146 19.1369 15.6404 18.8306 15.3283C18.5243 15.0162 18.3579 14.6034 18.3619 14.1661C18.3661 13.7289 18.5402 13.3193 18.8523 13.013C19.1607 12.7103 19.5673 12.5442 19.9987 12.5442C20.004 12.5442 20.0092 12.5442 20.0145 12.5442C20.4518 12.5483 20.8614 12.7225 21.1677 13.0346C21.474 13.3467 21.6404 13.7595 21.6364 14.1968C21.6323 14.6341 21.4582 15.0438 21.146 15.3501Z" fill="#FF640D" />
        </g>
        <defs>
            <clipPath id="clip0_72_265">
                <rect width="40" height="40" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

const Cards: React.FC = () => {
    const {t} = useTranslation();

    return (
        <div className="cards">
            <Card
                title={t('cards.card1.title')}
                description={t('cards.card1.description')}
                icon={icon1}
                backgroundColorIcon="rgba(90, 25, 207, 0.15)"
            />
            <Card
                title={t('cards.card2.title')}
                description={t('cards.card2.description')}
                icon={icon2}
                backgroundColorIcon="rgb(17, 186, 238, 0.15)"
            />
            <Card
                title={t('cards.card3.title')}
                description={t('cards.card3.description')}
                icon={icon3}
                backgroundColorIcon="rgba(255, 100, 13, 0.15)"
            />
        </div>
    )
}

export default Cards;